import React, { useRef, useState } from 'react'
import "../../Assets/Styles/Sections/Section1.scss"
import AOS from 'aos';
import 'aos/dist/aos.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";
import { FaArrowAltCircleRight } from "react-icons/fa"
import ModalPage from "../Sections/ModalPage"

const Section1 = () => {
    const recaptchaRef = React.createRef();
    const form = useRef();

    function onChange(value) {
        console.log("Captcha value:", value);
    }
    const [isModalOpen, setIsModalOpen] = useState(false);
    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Sending Mail....");
        emailjs.sendForm(
            'service_0ei0m9y',
            'template_7vqsytp',
            form.current,
            'ymjMeKsCwpSffaKHA'
        )
            .then((result) => {
                console.log(result.text);
                toast.success("Message Sent Successfully! We will get back to you soon.");
                e.target.reset();
                console.log("message sent");
            }, (error) => {
                toast.error("Message Sending Failed! Please try again.")
                console.log(error.text);
            });
    };
    AOS.init({
        duration: 1000,
    });
    return (
        <div className="main-banner-area">
            <div className="px-md-5 p-md-0 p-md-4">
                <div className='container col-12 d-flex flex-md-row flex-column align-items-center justify-content-center'>
                    <div className='col-md-7 col-12' data-aos="fade-up" data-aos-delay="50">
                        <div className='main-banner-content px-5'>
                            <h2 className=''> <strong>React</strong> Development <br className='d-md-flex d-none' /> Company</h2>
                            <p className='pt-md-4 pt-2'>
                                Boost your business's online presence with a professional website.
                            </p>
                            <div className='banner-btn mt-5 d-flex justify-content-md-start justify-content-center'>
                                <button className='default-btn1 btn-outline-dark d-flex flex-row gap-2' onClick={showModal}>
                                    <div>GET FREE QUOTE NOW</div>
                                    <div><FaArrowAltCircleRight size="20" className='arrowIcon' /></div>
                                </button>
                                <ModalPage
                                    onFinishFailed={onFinishFailed}
                                    onFinish={onFinish}
                                    handleCancel={handleCancel}
                                    showModal={showModal}
                                    isModalOpen={isModalOpen}
                                    setIsModalOpen={setIsModalOpen}
                                    sendEmail={sendEmail}
                                    form={form}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-5 pt-md-4 col-12 d-flex justify-content-center align-items-center'>
                        <div class="login " data-aos="fade-up" data-aos-delay="50">
                            <div class="heading">
                                <h4>Enquiry</h4>
                            </div>
                            <form ref={form} onSubmit={sendEmail}>
                                <div className='mt-4 sectionForm'>
                                    <input
                                        id="ContactForm1_contact-form-name"
                                        size="30"
                                        required
                                        type="text"
                                        name="user_name"
                                        class="form-control"
                                        placeholder='Name'
                                    />
                                </div>
                                <div class="mt-4 sectionForm">
                                    <input
                                        id="ContactForm1_contact-form-email"
                                        required
                                        size="30"
                                        type="email"
                                        name="user_email"
                                        class="form-control"
                                        placeholder='Email'
                                    />
                                </div>
                                <div class="mt-4 sectionForm">
                                    {/* <label for="exampleInputPassword1" class="form-label formLabel">
                                        Phone
                                    </label> */}
                                    <input
                                        required
                                        type="number"
                                        class="form-control"
                                        id="ContactForm1_contact-form-phone"
                                        name="user_phone"
                                        size="10"
                                        placeholder='Phone'
                                    />
                                </div>
                                <div class="mt-3 sectionForm">
                                    {/* <label for="exampleInputPassword1" class="form-label formLabel">
                                        Message
                                    </label> */}
                                    <textarea
                                        required
                                        id="ContactForm1_contact-form-email-message"
                                        class="form-control"
                                        rows="3"
                                        name="message"
                                        placeholder='Message'
                                    ></textarea>
                                </div>
                                <div className='mt-2 d-flex justify-content-center mt-3'>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey="6Lf5ZhslAAAAAH_E_1wxqGjNgemwye4d6tMIn5wj"
                                        onChange={onChange}
                                    />
                                </div>
                                <div class="col-12 text-center mt-3 ">
                                    <button type="submit" id="ContactForm1_contact-form-submit" class="col-12 btn btn-primary">SUBMIT</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section1