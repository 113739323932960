import React from 'react'
import Navbar from '../Navbar/Navbar'
import Section1 from '../Sections/Section1'
import Section10 from '../Sections/Section10'
import Section2 from '../Sections/Section2'
import Section3 from '../Sections/Section3'
import Section4 from '../Sections/Section4'
import Section5 from '../Sections/Section5'
import Section6 from '../Sections/Section6'
import Section7 from '../Sections/Section7'
import Section8 from '../Sections/Section8'
import Section9 from '../Sections/Section9'
import Section11 from '../Sections/Section11'
const LandingPage = () => {
    return (
        <div>
            <section>
                <Navbar />
            </section>
            <section>
                <Section1 />
            </section>
            <section>
                <Section2 />
            </section>
            <section>
                <Section3 />
            </section>
            <section >
                <Section4 />
            </section>
            <section >
                <Section10 />
            </section>
            <section >
                <Section11 />
            </section>
            <section >
                <Section5 />
            </section>
            <section >
                <Section6 />
            </section>
            <section >
                <Section7 />
            </section>
            <section >
                <Section8 />
            </section>
            <section >
                <Section9 />
            </section>
        </div>
    )
}

export default LandingPage