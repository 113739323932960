import React, { useState, useRef } from 'react'
import "../../Assets/Styles/Navbar/Navbar.css"
import logo from "../../Assets/Images/logos.png"
import ModalPage from '../Sections/ModalPage'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import { FiPhoneCall } from "react-icons/fi"
import { Link, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

const Navbar = () => {
    const [navbar, setNavbar] = useState(false)
    const form = useRef();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Sending Mail....");
        emailjs.sendForm(
            'service_0ei0m9y',
            'template_7vqsytp',
            form.current,
            'ymjMeKsCwpSffaKHA'
        )
            .then((result) => {
                console.log(result.text);
                toast.success("Message Sent Successfully! We will get back to you soon.");
                e.target.reset();
                setIsModalOpen(false);
                console.log("message sent");
            }, (error) => {
                toast.error("Message Sending Failed! Please try again.")
                console.log(error.text);
            });
    };

    const changeBackground = () => {
        if (window.scrollY >= 90) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }
    window.addEventListener('scroll', changeBackground)
    return (
        <>
            <div id="navbar" className={navbar ? 'navbar-area is-sticky fixed-top' : 'navbar-area fixed-top'}>
                <div className='container main-navbar mt-2'>
                    <div className='container-fluid'>
                        <nav class="navbar navbar-expand-lg navbar-light">
                            <a class="navbar-brand" href="/">
                                <img src={logo} class="img-fluid" width="200" />
                            </a>
                            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                            </button>
                            <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                                <ul class="navbar-nav me-auto">
                                    <Link to="about" smooth={true} duration={100}>
                                        <li class="nav-item">
                                            <a class="nav-link" aria-current="page" href="#">About Us</a>
                                        </li>
                                    </Link>
                                    <Link to="services" smooth={true} duration={100}>
                                        <li class="nav-item">
                                            <a class="nav-link" aria-current="page" href="#">Services</a>
                                        </li>
                                    </Link>
                                    <Link to="ourwork" smooth={true} duration={100}>
                                        <li class="nav-item">
                                            <a class="nav-link" aria-current="page" href="#">Our Work</a>
                                        </li>
                                    </Link>
                                    <Link to="features" smooth={true} duration={100}>
                                        <li class="nav-item">
                                            <a class="nav-link" aria-current="page" href="#">Our Features</a>
                                        </li>
                                    </Link>
                                    <Link to="features" smooth={true} duration={100}>
                                        <li class="nav-item">
                                            <a class="nav-link" aria-current="page" href="#">Contact us</a>
                                        </li>
                                    </Link>

                                </ul>
                            </div>
                            <div class="others-options d-md-flex align-items-center d-none">
                                <div className='option-item'>
                                    <ul>
                                        <li className='contact'>
                                            <i className='flaticon-telephone'><FiPhoneCall /></i>
                                            <p className='mt-2'>Call us today! <br /> <strong>+123(456)123</strong></p>
                                        </li>
                                    </ul>
                                    {/* <a class="default-btn" onClick={showModal}>CONTACT US</a> */}
                                </div>
                            </div>
                            <ModalPage
                                onFinishFailed={onFinishFailed}
                                onFinish={onFinish}
                                handleCancel={handleCancel}
                                showModal={showModal}
                                isModalOpen={isModalOpen}
                                setIsModalOpen={setIsModalOpen}
                                sendEmail={sendEmail}
                                form={form}
                            />
                        </nav>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Navbar