import React from 'react'
import "../../Assets/Styles/Sections/Section9.css"
import { FiSend, FiPhoneCall } from "react-icons/fi"
import { GoLocation, GoMailRead } from "react-icons/go"
import logo from "../../Assets/Images/logos.png"

const Section9 = () => {
    return (
        <footer class="bg-dark1 text-light footer1">
            <div class="fixed-shape">
                <img src="https://wordpressriverthemes.com/tanda/wp-content/themes/tanda/img/map.svg" alt="Shape" />
            </div>
            <div class="container">
                <div class="f-items default-padding">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 item">
                            <div class="f-item about">
                                <img src={logo} class="img-fluid" width="200" />
                                <p>
                                    Happen active county. Winding for the morning am shyness evident to poor. Garrets because elderly new. </p>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 item">
                            <div class="f-item link">
                                <h4 class="widget-title px-4">Company</h4>
                                <div class="menu-company-container"><ul id="menu-company" class="menu"><li id="menu-item-312" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-312"><a href="https://wordpressriverthemes.com/tanda/about-us/">About Us</a></li>
                                    <li id="menu-item-314" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-314">
                                        <a>Our Services</a>
                                    </li>
                                    <li id="menu-item-313" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-313">
                                        <a>Our Work</a>
                                    </li>
                                    <li id="menu-item-317" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-317">
                                        <a>Our Features</a>
                                    </li>
                                    <li id="menu-item-315" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-315">
                                        <a>Testimonials</a>
                                    </li>
                                </ul></div> </div>
                        </div>
                        <div class="col-lg-2 col-md-6 item">
                            <div class="f-item link">
                                <h4 class="widget-title px-4">Help</h4>
                                <div class="menu-solutions-container">
                                    <ul id="menu-solutions" class="menu">
                                        <li id="menu-item-319" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-319">
                                            <a href="https://wordpressriverthemes.com/tanda/services-version-one/">FAQ</a>
                                        </li>
                                        <li id="menu-item-321" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-321">
                                            <a href="https://wordpressriverthemes.com/tanda/services-version-two/">Terms & Conditions</a>
                                        </li>
                                        <li id="menu-item-320" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-320">
                                            <a href="https://wordpressriverthemes.com/tanda/services-version-three/">Privacy Policy</a>
                                        </li>
                                        <li id="menu-item-416" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-416">
                                            <a href="https://wordpressriverthemes.com/tanda/case-studies-version-one-2/">Refund Policy</a>
                                        </li>
                                        <li id="menu-item-419" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-419">
                                            <a href="https://wordpressriverthemes.com/tanda/case-studies-version-two/">Technologies</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 item">
                            <div class="f-item">
                                <h4 class="widget-title px-4">Contact Info</h4>
                                <div class="address">
                                    <ul>
                                        <li className='d-flex flex-row gap-3'>
                                            <div class="icon">
                                                <i class="flaticon-pin">
                                                    <GoLocation className='footerIcon' />
                                                </i>
                                            </div>
                                            <div class="info">
                                                5919 Trussville Crossings Pkwy, Birmingham AL 35235
                                            </div>
                                        </li>
                                        <li className='d-flex flex-row gap-3 mt-3'>
                                            <div class="icon">
                                                <i class="flaticon-email-1">
                                                    <GoMailRead className='footerIcon' />
                                                </i>
                                            </div>
                                            <div class="info">
                                                <a href="mailto:http://Info@gmail.com">Info@gmail.com</a> <br /> <a href="mailto:http://support@gmail.com">support@gmail.com</a>
                                            </div>
                                        </li>
                                        <li className='d-flex flex-row gap-3 mt-3'>
                                            <div class="icon">
                                                <i class="flaticon-telephone">
                                                    <FiPhoneCall className='footerIcon' />
                                                </i>
                                            </div>
                                            <div class="info">
                                                +123 456 7890 <br /> +456 123 4455 </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="footer-bottom">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12 d-flex justify-content-center">
                            <p>© 2023. Design & Developed by DLK Technologies Pvt Ltd. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Section9