import React from 'react'
import "../../Assets/Styles/Sections/Section11.css"
import reactjs from "../../Assets/Images/reactjs.png"
import sass from "../../Assets/Images/sass.png"
import bootstrap from "../../Assets/Images/bootstrap.png"
import rIcon from "../../Assets/Images/rIcon.png"
import fontaersome from "../../Assets/Images/fontaersome.png"
import rocket from "../../Assets/Images/rocket.svg"
import responsive from "../../Assets/Images/responsive.png"
import code from "../../Assets/Images/code.png"
import speed from "../../Assets/Images/speed.png"
import document from "../../Assets/Images/document.png"
import googlefont from "../../Assets/Images/google-font.png"
import antd from "../../Assets/Images/antd.png"

const Section11 = () => {
  return (
    <div className="more-features pt-90 pb-70">
      <div class="container">
        <div class="row">
          <div class="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
            <div class="section__title-wrapper section__title-wrapper-4 mb-70 text-center mt-5">
              <h2 class="section__title">React Core Features
              </h2>
              <p>We Provide Impressive Freatures For your Website. You can easily manage Your website. You will love it.</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-4 col-12 mb-30">
            <div class="bakix-features wow fadeInUp" >
              <img src={reactjs} alt="" />
              <h4>React</h4>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-12 mb-30">
            <div class="bakix-features wow fadeInUp" >
              <img src={sass} alt="" />
              <h4>Sass</h4>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-12 mb-30">
            <div class="bakix-features wow fadeInUp" >
              <img src={bootstrap} alt="" />
              <h4>Bootstrap v5</h4>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-12 mb-30">
            <div class="bakix-features wow fadeInUp">
              <img src={rIcon} alt="" />
              <h4>React Icons</h4>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-12 mb-30">
            <div class="bakix-features wow fadeInUp" >
              <img src={antd} alt="" />
              <h4>Ant Design</h4>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-12 mb-30">
            <div class="bakix-features wow fadeInUp" >
              <img src={fontaersome} alt="" />
              <h4>Fontawesome Icon</h4>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-12 mb-30">
            <div class="bakix-features wow fadeInUp">
              <img src={rocket} alt="" />
              <h4>Fast Performance</h4>
            </div>
          </div>


          <div class="col-lg-3 col-md-4 col-12 mb-30">
            <div class="bakix-features wow fadeInUp" >
              <img src={responsive} alt="" />
              <h4>fully responsive</h4>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-12 mb-30">
            <div class="bakix-features wow fadeInUp" >
              <img src={code} alt="" />
              <h4>Clean Code</h4>
            </div>
          </div>



          <div class="col-lg-3 col-md-4 col-12 mb-30">
            <div class="bakix-features wow fadeInUp" >
              <img src={speed} alt="" />
              <h4>Speed Optimized</h4>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-12 mb-30">
            <div class="bakix-features wow fadeInUp" >
              <img src={document} alt="" />
              <h4>Well Documented</h4>
            </div>
          </div>

          <div class="col-lg-3 col-md-4 col-12 mb-30">
            <div class="bakix-features wow fadeInUp" >
              <img src={googlefont} alt="" />
              <h4>Google Font</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section11