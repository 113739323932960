import React from 'react'
import "../../Assets/Styles/Sections/Section7.css"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Section7 = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 1
    }
  };
  return (
    <div className='standard-services-area bg-gray carousel-shadow default-padding'>
      <div className='container'>
        <div className='row'>
          <div className='site-heading text-center'>
            <h2>Lastest Projects</h2>
          </div>
          <div className='mt-5 maincarosuel'>
            <Carousel
              autoPlay={true}
              // removeArrowOnDeviceType={["tablet", "mobile", "superLargeDesktop", "desktop"]}
              responsive={responsive}
              autoPlaySpeed={3000}
              transitionDuration={500}
              showDots={false}
              dotListClass="custom-dot-list-style"
            >
              <div class="owl-item active" style={{width: "366.667px",marginRight: "20px"}}>
                <div class="item">
                  <div class="card">
                    <div class="card-body">
                      <blockquote class="icon mb-0">
                        <p className='offerPara mt-3'>"React Ninjas did great job in getting to complete the project the way I wanted. They have amazing communication skills, and in-depth understanding of React Ninjas services and support. I liked the way how they work, it was really commendable. I would highly recommend for their support and services."</p>
                        <div class="blockquote-details mt-4">
                          <img src="https://reactninjas.com/wp-content/uploads/2021/05/Beverly-Ann-300x300.jpg-1.webp" class="img-fluid img-shap" alt="image" width="60" height="60" />
                          <div class="info ">
                            <h5 class="mb-0">Beverly Ann</h5>
                            <p class="mb-0"></p>
                          </div>
                        </div>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
              <div class="owl-item active" style={{width: "366.667px",marginRight: "20px"}}>
                <div class="item">
                  <div class="card">
                    <div class="card-body">
                      <blockquote class="icon mb-0">
                        <p className='offerPara mt-3'>"React Ninjas did great job in getting to complete the project the way I wanted. They have amazing communication skills, and in-depth understanding of React Ninjas services and support. I liked the way how they work, it was really commendable. I would highly recommend for their support and services."</p>
                        <div class="blockquote-details mt-4">
                          <img src="https://reactninjas.com/wp-content/uploads/2021/05/Bradley-Reagan-300x300.jpg-1.webp" class="img-fluid img-shap" alt="image" width="60" height="60" />
                          <div class="info ">
                            <h5 class="mb-0">Beverly Ann</h5>
                            <p class="mb-0"></p>
                          </div>
                        </div>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
              <div class="owl-item active" style={{width: "366.667px",marginRight: "20px"}}>
                <div class="item">
                  <div class="card">
                    <div class="card-body">
                      <blockquote class="icon mb-0">
                        <p className='offerPara mt-3'>"React Ninjas did great job in getting to complete the project the way I wanted. They have amazing communication skills, and in-depth understanding of React Ninjas services and support. I liked the way how they work, it was really commendable. I would highly recommend for their support and services."</p>
                        <div class="blockquote-details mt-4">
                          <img src="https://reactninjas.com/wp-content/uploads/2021/05/Aaron-Cook-300x225.jpg.webp" class="img-fluid img-shap" alt="image" width="60" height="60" />
                          <div class="info ">
                            <h5 class="mb-0">Beverly Ann</h5>
                            <p class="mb-0"></p>
                          </div>
                        </div>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
              <div class="owl-item active" style={{width: "366.667px",marginRight: "20px"}}>
                <div class="item">
                  <div class="card">
                    <div class="card-body">
                      <blockquote class="icon mb-0">
                        <p className='offerPara mt-3'>"React Ninjas did great job in getting to complete the project the way I wanted. They have amazing communication skills, and in-depth understanding of React Ninjas services and support. I liked the way how they work, it was really commendable. I would highly recommend for their support and services."</p>
                        <div class="blockquote-details mt-4">
                          <img src="https://reactninjas.com/wp-content/uploads/2022/12/William-Scott-Ray-300x300.jpg.webp" class="img-fluid img-shap" alt="image" width="60" height="60" />
                          <div class="info ">
                            <h5 class="mb-0">Beverly Ann</h5>
                            <p class="mb-0"></p>
                          </div>
                        </div>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
              <div class="owl-item active" style={{width: "366.667px",marginRight: "20px"}}>
                <div class="item">
                  <div class="card">
                    <div class="card-body">
                      <blockquote class="icon mb-0">
                        <p className='offerPara mt-3'>"React Ninjas did great job in getting to complete the project the way I wanted. They have amazing communication skills, and in-depth understanding of React Ninjas services and support. I liked the way how they work, it was really commendable. I would highly recommend for their support and services."</p>
                        <div class="blockquote-details mt-4">
                          <img src="https://reactninjas.com/wp-content/uploads/2022/12/Alyne-Parker-300x300.jpg.webp" class="img-fluid img-shap" alt="image" width="60" height="60" />
                          <div class="info ">
                            <h5 class="mb-0">Beverly Ann</h5>
                            <p class="mb-0"></p>
                          </div>
                        </div>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
              <div class="owl-item active" style={{width: "366.667px",marginRight: "20px"}}>
                <div class="item">
                  <div class="card">
                    <div class="card-body">
                      <blockquote class="icon mb-0">
                        <p className='offerPara mt-3'>"React Ninjas did great job in getting to complete the project the way I wanted. They have amazing communication skills, and in-depth understanding of React Ninjas services and support. I liked the way how they work, it was really commendable. I would highly recommend for their support and services."</p>
                        <div class="blockquote-details mt-4">
                          <img src="https://reactninjas.com/wp-content/uploads/2022/12/Tom-Gainer-300x300.jpg.webp" class="img-fluid img-shap" alt="image" width="60" height="60" />
                          <div class="info ">
                            <h5 class="mb-0">Beverly Ann</h5>
                            <p class="mb-0"></p>
                          </div>
                        </div>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section7