import React from 'react'
import "../../Assets/Styles/Sections/Section3.css"
import bannerBg3 from "../../Assets/Images/bannerbg3.png"

const Section3 = () => {
    return (
        <div className='standard-services-area bg-gray carousel-shadow default-padding2'>
            <div className='shape d-md-flex d-none'>
                <img src={bannerBg3} />
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='site-heading text-center'>
                        <h2>How does React Ninjas Work?</h2>
                        <p className='mt-4'>
                            We have a team of highly skilled and experienced React developers
                            who can create amazing websites and applications as per your specific requirements:
                        </p>
                    </div>
                    <div className='mt-5 row gx-lg-8 gx-xl-12 gy-6 process-wrapper line'>
                        <div className='col-md-4 col-lg-4'>
                            <span class="icon btn btn-circle btn-lg btn-soft-primary home-sec-icon d-md-flex d-none">
                                <span class="number">1</span>
                            </span>
                            <h4 className='listed'>Analyzing</h4>
                            <p></p>
                            <p className='reactAnalysis'>
                                We will analyze your project requirements and find the perfect solution for your website.
                            </p>
                            <p></p>
                        </div>
                        <div className='col-md-4 col-lg-4'>
                            <span class="icon btn btn-circle btn-lg btn-primary d-md-flex d-none">
                                <span class="number">2</span>
                            </span>
                            <h4 className='listed'>Development</h4>
                            <p></p>
                            <p className='reactAnalysis'>Upon analysis, we will begin the developing process of the site under
                                certified developers and team for delivering the promising results to the client.
                            </p>
                            <p></p>
                        </div>
                        <div className='col-md-4 col-lg-4'>
                            <span class="icon btn btn-circle btn-lg btn-soft-primary home-sec-icon d-md-flex d-none">
                                <span class="number">3</span>
                            </span>
                            <h4 className='listed'>Go Live</h4>
                            <p></p>
                            <p className='reactAnalysis'>After receiving your feedback, we work on them and make the site go live.
                                Our tech team is available to provide assistance should you wish to go live from your end.
                            </p>
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section3