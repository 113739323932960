import React from 'react'
import "../../Assets/Styles/Sections/Section5.css"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Section5 = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 1
    }
  };
  return (
    <div className='standard-services-area bg-gray carousel-shadow default-padding'>
      <div className='container'>
        <div className='row'>
          <div className='site-heading text-center'>
            <h2>Lastest Projects</h2>
          </div>
          <div className='mt-5 px-5'>
            <Carousel
              autoPlay={true}
              // removeArrowOnDeviceType={["tablet", "mobile", "superLargeDesktop", "desktop"]}
              responsive={responsive}
              autoPlaySpeed={3000}
              transitionDuration={500}
              showDots={false}
              dotListClass="custom-dot-list-style"
            >
              <div class="owl-item active" style={{ width: "360px", marginRight: "30px" }}>
                <div class="item">
                  <figure class="rounded mb-7">
                    <a>
                      <img src="https://reactninjas.com/wp-content/uploads/2022/12/portfolio-1.png" alt="" />
                    </a>
                  </figure>
                  <div class="project-details d-flex justify-content-center flex-column">
                    <div class="post-header">
                      <h2 class="post-title h3">
                        <a class="link-dark">Cryptocurrency App</a>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div class="owl-item active" style={{ width: "360px", marginRight: "30px" }}>
                <div class="item">
                  <figure class="rounded mb-7">
                    <a>
                      <img src="https://reactninjas.com/wp-content/uploads/2022/12/portfolio-2.png" alt="" />
                    </a>
                  </figure>
                  <div class="project-details d-flex justify-content-center flex-column">
                    <div class="post-header">
                      <h2 class="post-title h3">
                        <a class="link-dark">Cryptocurrency App</a>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div class="owl-item active" style={{ width: "360px", marginRight: "30px" }}>
                <div class="item">
                  <figure class="rounded mb-7">
                    <a>
                      <img src="https://reactninjas.com/wp-content/uploads/2022/12/portfolio-4.png" alt="" />
                    </a>
                  </figure>
                  <div class="project-details d-flex justify-content-center flex-column">
                    <div class="post-header">
                      <h2 class="post-title h3">
                        <a class="link-dark">Cryptocurrency App</a>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div class="owl-item active" style={{ width: "360px", marginRight: "30px" }}>
                <div class="item">
                  <figure class="rounded mb-7">
                    <a>
                      <img src="https://reactninjas.com/wp-content/uploads/2022/12/portfolio-3.png" alt="" />
                    </a>
                  </figure>
                  <div class="project-details d-flex justify-content-center flex-column">
                    <div class="post-header">
                      <h2 class="post-title h3">
                        <a class="link-dark">Cryptocurrency App</a>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
           
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Section5