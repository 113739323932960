import React from 'react'
import "../../Assets/Styles/Sections/Section4.css"

const Section4 = () => {
    return (
        <div className='iq-tab services-tab what-do-offer'>
            <div class="container">
                <div class="row services-offers">
                    <div class="col-md-5 position-sticky">
                        <div class="section-title">
                            <p class="iq-rmt-40 text-uppercase  iq-fw-3 iq-ls-3 mb-1 headerWithTitle">Best Ever Services</p>
                            <h2 class="title iq-fw-8 mt-3">What do we offer?</h2>
                            <p class="mt-4 mb-3 offerPara ">
                                DLK Technologies is a renowned name for offering world-class
                                React Website development services. We have a team of highly skilled and experienced React
                                developers who are experts in developing cutting-edge websites that are not only visually appealing
                                but also deliver superior performance. With us, you can be assured of getting a website that is
                                tailor-made to meet your specific business requirements and goals. So, if you are looking for a
                                reliable partner for your React Web development needs,
                                then DLK Technologies is the right choice for you.
                            </p>
                            <div class="cms-banner-btn">
                                <a class="m-store" href="https://reactninjas.com/hire-react-developer/">Hire React Developer</a>
                                <a href="https://reactninjas.com/contact/">Contact us</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="container tab-content">
                            <div class="container main-content">
                                <div class="row justify-content-between">
                                    <div class="col-lg-12 col-md-12 text-left align-self-center cardWithcontent">
                                        <h2 class="title iq-fw-8">React Web Development</h2>
                                        <p className='offerPara'>React is leading in the market by the popularity of developing big-screen projects. Construct your dynamic and creative web applications with React under the master group of advancement.</p>
                                        <ul class="listing-mark mt-4">
                                            <li>Easy to use</li>
                                            <li>Speed &amp; Flexibility</li>
                                            <li>Flux architecture</li>
                                        </ul>
                                        <a href="javascript:void(0)" data-toggle="modal" data-target="#GetaQuoteModal" data-name="Let’s discuss">Let’s Discuss</a>
                                    </div>
                                </div>
                            </div>
                            <div class="container main-content">
                                <div class="row justify-content-between">
                                    <div class="col-lg-12 col-md-12 text-left align-self-center cardWithcontent">
                                        <h2 class="title iq-fw-8">Custom React Development</h2>
                                        <p className='offerPara'>Construct your tailor-made website or application with the master help to custom React improvements like Plugin Development, Integration, and Migration for customers.</p>
                                        <ul class="listing-mark mt-4">
                                            <li>High compatibility</li>
                                            <li>Easy to create Dynamic Web applications</li>
                                            <li>Reusable and customizable components</li>
                                        </ul>
                                        <a href="javascript:void(0)" data-toggle="modal" data-target="#GetaQuoteModal" data-name="Let’s discuss">Let’s Discuss</a>
                                    </div>
                                </div>
                            </div>
                            <div class="container main-content">
                                <div class="row justify-content-between">
                                    <div class="col-lg-12 col-md-12 text-left align-self-center cardWithcontent">
                                        <h2 class="title iq-fw-8">React Native App Development</h2>
                                        <p className='offerPara'>React has a team of experienced React developers who can help you migrate your website or mobile application handily &amp; quickly on the React framework.</p>
                                        <ul class="listing-mark mt-4">
                                            <li>Fast applications</li>
                                            <li>SEO User friendly</li>
                                            <li>Large developer community </li>
                                        </ul>
                                        <a href="javascript:void(0)" data-toggle="modal" data-target="#GetaQuoteModal" data-name="Let’s discuss">Let’s Discuss</a>
                                    </div>
                                </div>
                            </div>
                            <div class="container main-content">
                                <div class="row justify-content-between">
                                    <div class="col-lg-12 col-md-12 text-left align-self-center cardWithcontent">
                                        <h2 class="title iq-fw-8">Maintenance &amp; Support</h2>
                                        <p className='offerPara'>We create powerful, feature-rich application that ensures incredible digital experience to users of different business industries. Our apps are.</p>
                                        <ul class="listing-mark mt-4">
                                            <li>Structured Packages</li>
                                            <li>Bug Fixes</li>
                                            <li>Easily upgradable</li>
                                        </ul>
                                        <a href="javascript:void(0)" data-toggle="modal" data-target="#GetaQuoteModal" data-name="Let’s discuss">Let’s Discuss</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section4