import React from 'react'
import "../../Assets/Styles/Sections/Section8.css"

const Section8 = () => {
    return (
        <footer className='footer-one footer-bg  position-relative'>
            <div className='iq-subscribe pattern-left  container position-relative lets-discuss-main' id="iq-subscribe">
                <div className='container'>
                    <div className='container row'>
                        <div class="col-sm-4 align-self-center">
                            <div class="section-title">
                                <h2 class="title iq-fw-8">Let's Discuss</h2>
                                <p className='offerPara'>Please fill the form and we will get back to you same day.</p>
                            </div>
                        </div>
                        <div className='col-sm-8 text-center'>
                            <form>
                                <div class="row justify-content-center">
                                    <div class="col-lg-12">
                                        <div class="project-form mb-3 lets-discuss-form">
                                            <div class="three-row">
                                                <div class="form-group">
                                                    <span class="wpcf7-form-control-wrap" data-name="first-name">
                                                        <input type="text" name="first-name" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required form-control contact-first-name" aria-required="true" aria-invalid="false" placeholder="Full Name" />
                                                    </span>
                                                </div>
                                                <div class="form-group">
                                                    <span class="wpcf7-form-control-wrap" data-name="phone">
                                                        <input type="tel" name="phone" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel form-control contact-phone" aria-required="true" aria-invalid="false" placeholder="Your Mobile No." />
                                                    </span>
                                                </div>
                                                <div class="form-group">
                                                    <span class="wpcf7-form-control-wrap" data-name="email">
                                                        <input type="email" name="email" value="" size="40" class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email form-control contact-email" aria-required="true" aria-invalid="false" placeholder="Your Email Address" />
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="single-row mt-2">
                                                <div class="form-group">
                                                    <span class="wpcf7-form-control-wrap" data-name="message">
                                                        <textarea name="message" cols="40" rows="10" class="wpcf7-form-control wpcf7-textarea form-control contact-message textarea1" aria-invalid="false" placeholder="Your requirement">
                                                        </textarea>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="button-row">
                                                <div class="align-items-center">
                                                    <div class="text-center  d-flex justify-content-md-end justify-content-center subscribe-letsdiscuss-btn">
                                                        <button type="submit" id="ContactForm1_contact-form-submit" class=" btn btn-dark contact-us-btn">SUBMIT</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Section8